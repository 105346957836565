var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("loader"),
      _c(
        "AppHeader",
        { attrs: { fixed: "" } },
        [
          _c(
            "b-link",
            { staticClass: "navbar-brand", attrs: { to: "/lista-vez" } },
            [
              _c("img", {
                staticClass: "navbar-brand-full pl-md-4",
                attrs: {
                  src: "img/brand/logo.png",
                  height: "22",
                  alt: "Taylor Logo"
                }
              })
            ]
          ),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-white btn-sm mr-4",
                  attrs: { variant: "primary" },
                  on: { click: _vm.logout }
                },
                [
                  _c("i", { staticClass: "icon-logout icons" }),
                  _vm._v(" Sair\n      ")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "app-body" }, [
        _c(
          "div",
          { staticClass: "container-fluid p-2" },
          [_c("router-view")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }