<template>
  <div class="app">
    <loader />
    <AppHeader fixed>
      <b-link class="navbar-brand" to="/lista-vez">
        <img class="navbar-brand-full pl-md-4" src="img/brand/logo.png" height="22" alt="Taylor Logo">
      </b-link>
      <b-navbar-nav class="ml-auto">
        <b-button variant="primary" class="text-white btn-sm mr-4" @click="logout">
          <i class="icon-logout icons"></i> Sair
        </b-button>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <div class="container-fluid p-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import nav from '@/_nav'
import { Header as AppHeader } from '@coreui/vue'
import Loader from '@/core/components/Loader'
import session from '../shared/session'

export default {
  name: 'DefaultContainerListaVez',
  components: {
    AppHeader,
    Loader
  },
  data () {
    return {
      nav: nav.items
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT');
      this.$router.push({path: '/'});
    }
  }
}
</script>